














































import { Vue, Component, Watch } from 'vue-property-decorator';
import {ArmazenamentoService} from '@/core/services/residuo';
import {Armazenamento} from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';

@Component
export default class ListaAcondicionamento extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Código', value: 'codigo' },
    { text: 'Nome', value: 'nome' },
  ];
  item = new Armazenamento();
  service = new ArmazenamentoService();

  @Watch('options', { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, ['Codigo'], sortDesc, this.search, this.headers).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  AbrirDialogCadastro(item?: Armazenamento){  
    if(item){
      this.service.ObterPorId(item.id).then(
        res=>{
          this.item = res.data
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    else{
      this.item = new Armazenamento();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: Armazenamento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id)
          .then(res => {
              if (res.status == 200){
                  return res.data;
              }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
          )
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }
}

